import React, { useEffect, useState } from "react";
//import SectionsHead from "../../Home/Heading";
import profile from "../../../images/a2z-bg.jpg";
import axios from "axios";
import "../../../style/partials/components/profile.scss";
import { API } from "../../../api-config";
import { useParams } from "react-router-dom";
function CounsellorDetails() {
  const [data, setData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    counselorProfile();
  }, [id]);

  const counselorProfile = async () => {
    try {
      let myData = await axios.get(
        `${API}/api/counselor-profile/getdetails`,
        {
          params: { id },
        }
      );
      setData(myData.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
    <section className="container mt-5 mb-5">
      {data &&
        data.map((x, index) => {
          return (
            <div
              className="container profile-cart p-3 col-lg-12  shadow-sm mt-4"
              key={index}
            >
              <div className="row">
                <div className="col-12 col-md-12 id-card">
                  <div className="col-12 col-md-6 id-card-det">
                    <div className="col-4 col-md-4 col-md-3">
                      <div className="profile-cart-img text-center">
                          <img
                            className="hero-image"
                            src={x?.profilePic && x.profilePic.length > 0 ? x.profilePic[0] : profile}
                             alt={`${x?.userId[0]?.fName} ${x?.userId[0]?.lName}`}
                            loading="lazy"
                          />
                      </div>
                    </div>

                    <div className="col-8 col-md-8 align-self-center">
                      <div className="profile-header-detail ">
                        <h6>
                          {x.userId?.fName.charAt(0).toUpperCase() +
                            x.userId.fName.slice(1) +
                            " " +
                            x.userId?.lName.charAt(0).toUpperCase() +
                            x.userId?.lName.slice(1)}
                        </h6>
                        <div className="pt-2 pb-2 time-left flex-wrap">
                          <label className="fw-bolder">Skills: </label>
                          {x?.skills?.map((ele, index2) => (
                            <p className="m-0 ml-1" key={`${ele.skill}${index2}`}>
                              {ele.skill}
                              {index2 < x.skills.length - 1 ? ',  ' : ''}
                            </p>
                          ))}
                        </div>

                        <p className="mb-2">
                          {x.userId?.defaultCurrency && `Currency: ${x.userId.defaultCurrency}`}
                        </p>
                        <p className="mb-2">Experience: {x.experience}</p>
                        <p className="mb-2">
                          {x.city && `${x.city}, `}
                          {x.state && `${x.state}, `}
                          {x.country && `${x.country} `}
                          {x.zip && x.zip}
                        </p>
                        <p className="mb-2">
                          {x.createdAt && `Register at: ${new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric'
                          }).format(new Date(x.createdAt))}`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 align-self-center">
                  <p>{x.description}</p>
                </div>
              </div>
            </div>
          );
        })}
    </section>
    </>
  );
}
export default React.memo(CounsellorDetails);
